<template>
  <div  class="modalClass" ref="container">
    <a-modal
      :visible="visible"
      title="使用协议"
      :width="600"
      centered
      :destroyOnClose="true"
      :getContainer="() => container"
      @cancel="close"
    >
      <!--  注销账号form  :footer="null" -->
      <div class="logoutContentT">
        <div class="textl textBold">特别提示</div>
        <div class="textTwo mt-20">
          <div>
            “人脉宝盒”产品团队（以下简称“人脉宝盒团队”）在此特别提醒您（用户）在注册成为用户之前，请认真阅读本《用户协议》
            （以下简称“协议”），确保您充分理解本协议中各条款，特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、
            同意开通和使用特殊单项服务的条款、法律适用和争议解决条款等。其中，免除或者限制责任条款等重要内容将以加粗形式提示您注意，
            请您重点阅读。如您未满
            <span class="textBold">
              18
            </span>周岁，请您在法定监护人陪同下仔细阅读并充分理解本协议，尤其是其中的未成年人使用条款，并在征得法定监
            护人同意后使用人脉宝盒。请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议
            所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。
          </div>
          <br>

          <div>
            本协议约定人脉宝盒团队与用户之间关于“人脉宝盒”软件服务（以下简称“服务”）的权利义务。
            “用户”是指注册、登录、使用本服务的个人。本协议可由人脉宝盒团队随时更新，更新后的协议条款一旦公布即代替原来的协议条款，
            并会通过主动弹窗、消息提醒等方式主动通知用户，需用户阅读同意，用户可在本网站查阅最新版协议条款。在人脉宝盒团队修改协议条款后，
            如果用户不接受修改后的条款，请立即停止使用人脉宝盒团队提供的服务，用户继续使用人脉宝盒团队提供的服务将被视为接受修改后的协议。
          </div>
         <br>

        <div>
          <span class="textBold">一、帐号注册 </span>
          <br>
          <span class="textBold5"> 1、 </span>用户在使用本服务前需要注册一个“人脉宝盒”帐号。“人脉宝盒”帐号应当使用手机号码或微信号绑定注册，
          请用户使用尚未与“人脉宝盒”帐号绑定的手机号或微信号，以及未被人脉宝盒团队根据本协议封禁的手机号或微信号注册“人脉宝盒”帐号。
          人脉宝盒团队可以根据用户需求或产品需要对帐号注册和绑定的方式进行变更，并会在账号注册和绑定方式发生变更时通过主动弹窗、消息提
          醒等方式主动通知用户。
          <br>
          <span class="textBold5"> 2、 </span>“人脉宝盒”系基于手机联系人的人脉管理产品，用户注册时应当授权人脉宝盒软件使用其手机
          联系人信息方可成功注册“人脉宝盒”帐号。故用户完成注册即表明用户同意人脉宝盒软件使用用户的手机联系人信息。
          <br>


          <span class="textBold5"> 3、</span>  鉴于“人脉宝盒”帐号的绑定注册方式：
          <br>
          <span class="textBold5">（1）</span> 您同意人脉宝盒软件在注册或登录时使用您提供的手机号；
          <br>
          <span class="textBold5"> （2）</span>  您同意给予运营商授权，授权运营商有权自动提取您的手机号码进行认证并用于“人脉宝盒”账号注册。经过您的同意，我们会使用运营商提供的功能，将您设备中的手机号码作为您的注册、登录账号并免于密码或动态验证码核验，为您提供快捷的注册、登录服务。如果您不希望使用这项功能，可以通过其他方式注册、登录；
          <br>
          <span class="textBold5"> （3）</span> 您保证遵守运营商的相关服务条款，如运营商对您的手机号认证成功，则您的注册即完成；
          <br>
          <span class="textBold5"> （4）</span> 如您不同意对运营商的授权和/或服务条款或者是您的手机号认证失败，您可以手动修改运营商提取的手机号码，采取验证码方式进行注册登录；
          <br>
          <span class="textBold5">  4、</span>在用户注册及使用本服务时，人脉宝盒软件需要搜集能识别用户身份的个人信息以便人脉宝盒团队可以在必要时联系用户，
          或为用户提供更好的使用体验。为实现用户所选择使用的功能，人脉宝盒软件搜集的信息包括但不限于用户的姓名、
          性别、年龄、出生日期、地址、学校情况、公司情况、所属行业、个人说明；人脉宝盒团队对这些信息的使用将受限于【三、用户个人信息保护】的约束。
          <br>
          <br>
          <span class="textBold">二、服务内容</span>
          <br>
          <span class="textBold5">1、  </span> 本服务的具体内容由人脉宝盒团队根据实际情况提供，包括但不限于授权用户通过人脉宝盒软件进行电话、短信、即时通讯、
          添加好友、加入群组、关注他人、发布留言。为提升用户体验，或基于整体服务运营、平台安全、合规经营的需要，我们可能不
          定期更新或变更人脉宝盒服务内容，包括但不限于修改、升级、中止或终止相关服务、提供新服务或软件包替换等，您可根据需要自
          行选择是否更新。如您选择不更新，则人脉宝盒的部分功能可能受到限制或不能正常使用。为了方便您更好地使用，我们也将在适当时候提醒您变更内容与进行更新。
          <br>
          <span class="textBold5">  2、 </span>人脉宝盒团队提供的服务包含免费服务与收费服务。用户可以通过付费方式购买收费服务，具体方式为：通过微信、支付宝，在“
          人脉宝盒”软件支付一定数额的人民币，用以购买“人脉宝盒”软件使用权益或者虚拟金币，用户从而获得对应使用权益或通过虚拟金币兑换相应使用权益。
          对于收费服务，人脉宝盒软件会在用户使用之前给予用户明确的提示，只有用户根据提示确认其同意按照前述支付方式支付费用并完成了支付行为，用户才能
          使用该等收费服务。支付行为的完成以第三方支付平台生成“支付已完成”的确认通知为准。
          <br>
          <br>
          <span class="textBold">三、用户个人信息保护 </span>
          <br>
          <span class="textBold5"> 1、 </span>  用户在注册帐号或使用本服务的过程中，可能需要填写或提交一些必要的个人信息，如法律法规、规章规范性文件
          （以下称“法律法规”）规定的需要填写的身份信息。如用户提交的信息不完整或不符合法律法规的规定，则用户可能无法使用本服务或在使用本服务的过程中受到限制。
          <br>
          <span class="textBold5"> 2、 </span> 用户个人信息包括： <span class="textBold5"> （1）</span> 用户自行提供的用户个人信息（如注册时填写的手机号码，
          电子邮件等个人信息，使用服务时提供的共享信息等）。 <span class="textBold5"> （2）</span> 其他方分享的用户个人信息。
          <span class="textBold5"> （3）</span> 人脉宝盒软件为提供服务而合法收集的用户必要个人信息（如使用服务时系统自动采集的设备或软件信息，
          浏览历史信息，通讯时间信息等技术信息，用户开启定位功能并使用服务时的地理位置信息等）。

          <br>
          其中个人隐私信息是指涉及用户个人身份或个人隐私的信息，比如，用户真实姓名、身份证号、手机号码、手机设备识别码、
          <span class="textBold5"> IP </span> 地址。非个人隐私信息是指用户对本服务的操作状态以及使用习惯等明确且客观反映在人脉宝盒软件服务器端的基本记录信息、
          个人隐私信息范围外的其它普通信息，以及用户同意公开的上述隐私信息。人脉宝盒团队将会在取得用户同意的情况下收集、使用或公开用户的个人隐私信息。
          <br>
          <span class="textBold5">  3、 </span> 尊重用户个人信息的私有性是人脉宝盒团队的一贯宗旨，人脉宝盒团队将采取技术措施和其他必要措施，确保用户个人信息安全，防止在本服务中收集的用户个人信息泄露、毁损或丢失。在发生前述情形或者人脉宝盒团队发现存在发生前述情形的可能时，人脉宝盒团队将及时采取补救措施并告知用户，用户如发现存在前述情形亦需立即与人脉宝盒团队联系。
          <br>

          <span class="textBold5"> 4、 </span>人脉宝盒团队未经用户同意不向任何第三方公开、 透露用户个人隐私信息。但以下特定情形除外：
          <br>
          <span class="textBold5">（1） </span>根据用户的要求订立或履行合同所必需；
          <br>
          <span class="textBold5"> （2）</span>为履行法定职责或者法定义务所必需；
          <br>
          <span class="textBold5"> （3） </span>为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
          <br>
          <span class="textBold5">  （4）</span>为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
          <br>
          <span class="textBold5"> （5）</span>在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；
          <br>
          <span class="textBold5"> （6）</span>法律、行政法规规定的其他情形。
          <br>

          <span class="textBold5"> 5、  </span>用户同意人脉宝盒团队在以下事项中使用了用户的个人隐私信息不承担责任：
          <br>
          <span class="textBold5"> (1)   </span>人脉宝盒团队根据法律法规规定或有权机关的指示提供用户的个人隐私信息；
          <br>
          <span class="textBold5">  (2)  </span>由于用户将其用户密码告知他人或与他人共享注册帐户与密码，由此导致的任何个人信息的泄漏，或其他非因人脉宝盒团队原因导致的个人隐私信息的泄露；
          <br>
          <span class="textBold5">(3)   </span>用户自行向第三方公开其个人隐私信息；
          <br>
          <span class="textBold5"> (4)   </span>用户与人脉宝盒团队及合作单位之间就用户个人隐私信息的使用公开达成约定，人脉宝盒团队因此向合作单位公开用户个人隐私信息；
          <br>
          <span class="textBold5"> (5)  </span>任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露；
          <br>
          <span class="textBold5"> (6)  </span>用户个人信息已经经过处理无法识别特定个人且不能复原；
          <br>
          <span class="textBold5"> (7) </span>人脉宝盒团队向用户及时发送重要通知，如软件更新、本协议条款的变更；
          <br>
          <span class="textBold5">  (8) </span>人脉宝盒团队内部进行审计、数据分析和研究等，以改进人脉宝盒团队的产品、服务和与用户之间的沟通；
          <br>
          <span class="textBold5">  (9) </span>依本协议约定，人脉宝盒团队管理、审查用户信息及进行处理措施；
          <br>
          <span class="textBold5">  (10) </span>适用法律法规规定的其他事项。
          <br>
          除上述事项外，如未取得用户事先同意，人脉宝盒团队不会将用户个人隐私信息使用于任何其他用途。
          <br>


          <span class="textBold5"> 6、 </span>人脉宝盒团队重视对未成年人的保护。人脉宝盒团队将依赖用户提供的个人信息判断用户是否为未成年人。人脉宝盒团队有权根据各项服务性质决定是否向未成年人提供全部或部分服务。
          <br>
          <span class="textBold5"> 7、 </span>因人脉宝盒软件可能提供基于地理位置提供的移动社交服务，用户确认，用户成功注册“人脉宝盒”帐号视为确认授权人脉宝盒团队提取、公开及使用用户的地理位置信息。用户地理位置信息将作为用户公开资料之一，由人脉宝盒团队向其他用户公开以便人脉宝盒团队向用户提供基于地理位置的移动社交服务。
          <br>
          <span class="textBold5"> 8、 </span>为了改善人脉宝盒软件的技术和服务，向用户提供更好的服务体验，经用户同意后，人脉宝盒软件或可会自行收集使用或向第三方提供用户的非个人隐私信息。
          <br>
          <span class="textBold5"> 9、 </span>人脉宝盒团队保证在合法、正当与必要的原则下收集、使用或者公开用户个人信息且不会收集与提供的服务无关的用户个人信息。
          <br>
          <span class="textBold5"> 10、 </span>人脉宝盒团队十分注重保护用户的个人隐私，
          并制定了《人脉宝盒隐私权政策》
          （<a @click='openPrivacyAgreementPage'>点击查看</a>），用户确认并同意使用人脉宝盒团队提供的服务将被视为接受《人脉宝盒隐私权政策》。
          <br>
          <br>
          <span class="textBold">四、内容规范 </span>
          <br>
          <span class="textBold5"> 1、 </span>本条所述内容是指用户使用本服务过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于帐号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复或自动回复消息和相关链接页面，以及其他使用帐号或本服务所产生的内容。
          <br>
          <span class="textBold5"> 2、</span>用户不得利用“人脉宝盒”软件或本服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容：
          <br>
          <span class="textBold5"> (1) </span>反对宪法所确定的基本原则的；
          <br>
          <span class="textBold5">  (2)  </span>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
          <br>
          <span class="textBold5"> (3) </span>损害国家荣誉和利益的；
          <br>
          <span class="textBold5"> (4)  </span>煽动民族仇恨、民族歧视，破坏民族团结的；
          <br>
          <span class="textBold5"> (5)  </span>破坏国家宗教政策，宣扬邪教和封建迷信的；
          <br>
          <span class="textBold5"> (6)  </span>散布谣言，扰乱社会秩序，破坏社会稳定的；
          <br>
          <span class="textBold5"> (7)  </span>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
          <br>
          <span class="textBold5">  (8)  </span>侮辱或者诽谤他人，侵害他人合法权益的；
          <br>
          <span class="textBold5">  (9)  </span>不遵守法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的；
          <br>
          <span class="textBold5">  (10) </span>含有法律、行政法规禁止的其他内容的信息。
          <br>

          <span class="textBold5">  3、</span> 用户不得利用“人脉宝盒”软件或本服务制作、上载、复制、发布、传播如下干扰“人脉宝盒”正常运营，以及侵犯其他用户或第三方合法权益的内容：
          <br>
          <span class="textBold5">  (1) </span>含有任何性或性暗示的；
          <br>
          <span class="textBold5">  (2) </span>含有辱骂、恐吓、威胁内容的；
          <br>
          <span class="textBold5">  (3) </span>含有骚扰、垃圾广告、恶意信息、诱骗信息的；
          <br>
          <span class="textBold5">  (4) </span>涉及他人隐私、个人信息或资料的；
          <br>
          <span class="textBold5">   (5) </span>侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；
          <br>
          <span class="textBold5">  (6) </span>含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。
          <br>
          <br>
          <span class="textBold">   五、使用规则</span>
          <br>
          <span class="textBold5">1、</span> 用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表人脉宝盒团队的观点、立场或政策，人脉宝盒团队对此不承担任何责任。
          <br>
          <span class="textBold5">   2、</span>用户不得利用“人脉宝盒”帐号或本服务进行如下行为：
          <br>
          <span class="textBold5">   (1) </span>提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；
          <br>
          <span class="textBold5"> (2)  </span>强制、诱导其他用户关注、点击链接页面或分享信息的；
          <br>
          <span class="textBold5">  (3) </span>虚构事实、隐瞒真相以误导、欺骗他人的；
          <br>
          <span class="textBold5">  (4) </span>利用技术手段批量建立虚假帐号的；
          <br>
          <span class="textBold5">  (5) </span>利用“人脉宝盒”帐号或本服务从事任何违法犯罪活动的；
          <br>
          <span class="textBold5">  (6) </span>制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；
          <br>
          <span class="textBold5">  (7) </span>其他违反法律法规规定、侵犯其他用户合法权益、干扰“人脉宝盒”正常运营或人脉宝盒团队未明示授权的行为。
          <br>
          <span class="textBold5">  3、</span>用户须对利用“人脉宝盒”帐号或本服务传送信息的真实性、合法性、无害性、准确性、有效性等全权负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与人脉宝盒团队无关。如因此给人脉宝盒团队或第三方造成损害的，用户应当依法予以赔偿。
          <br>
          <span class="textBold5">  4、</span> 人脉宝盒软件提供的服务中可能包括广告，用户同意在使用过程中显示人脉宝盒软件和第三方供应商、合作伙伴提供的广告。除法律法规明确规定外，用户应自行对依该广告信息进行的交易负责，对用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，人脉宝盒团队不承担任何责任。
          <br>
          <span class="textBold5"> 5、 </span>除非人脉宝盒团队书面许可，用户不得从事下列任一行为：
          <br>
          <span class="textBold5">  (1) </span>删除软件及其副本上关于著作权的信息；
          <br>
          <span class="textBold5">  (2) </span>对软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现软件的源代码；
          <br>
          <span class="textBold5">  (3) </span>对人脉宝盒团队拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
          <br>
          <span class="textBold5">  (4) </span> 对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经人脉宝盒团队授权的第三方工具/服务接入软件和相关系统；
          <br>
          <span class="textBold5">  (5) </span>通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
          <br>
          <span class="textBold5">  (6) </span>通过非人脉宝盒团队开发、授权的第三方软件、插件、外挂、系统，登录或使用人脉宝盒团队软件及服务，或制作、发布、传播非人脉宝盒团队开发、授权的第三方软件、插件、外挂、系统。
          <br>

          <br>
          <span class="textBold">六、虚拟金币 </span>
          <br>
          <span class="textBold5"> 1、 </span>人脉宝盒团队将在“人脉宝盒”软件提供积分性质的虚拟金币。虚拟金币可用于兑换“人脉宝盒”软件的增值服务，
          不得用于其他任何用途。该增值服务的兑换方式和标准将由人脉宝盒团队自行决定并在相关服务页面上以弹窗、
          消息推送等方式进行提示。
          <br>
          <span class="textBold5">2、  </span>用户默认已开通虚拟金币账户，可进行虚拟金币购买（下称“充值”）和消费。用户可在设置页面查询到虚拟金币余额、充值记录和消费记录。虚拟金币相关信息将不作为公开信息。
          <br>
          <span class="textBold5"> 3、 </span>用户可以通过微信支付、支付宝或其他“人脉宝盒”软件提供的充值途径为虚拟金币账户进行充值。用户确认不会以非法方式或者使用非软件所指定的充值途径进行充值,如果用户违规使用非人脉宝盒团队认可的充值途径进行非法充值/购买虚拟金币，则人脉宝盒团队不保证充值顺利或正确完成，若因此造成用户权益受损，人脉宝盒团队将不会作出任何补偿或赔偿，人脉宝盒团队同时保留随时终止用户人脉宝盒账号资格及使用各项充值服务的权利，并进行相应惩罚。
          <br>
          <span class="textBold5"> 4、 </span>用户确认在进行充值前已经仔细确认过自己的账号并仔细选择了相关操作选项，若因用户自身输入账号错误、操作不当或不了解充值计费方式等因素造成充错账号、错选充值种类等情形而损害自身权益的，人脉宝盒团队将不会作出任何补偿或赔偿。
          <br>
          <span class="textBold5"> 5、 </span>用户确认，虚拟金币一经充值成功，除法律法规明确规定外，在任何情况下不能兑换为法定货币，不能转让他人。除法律法规明确规定外，虚拟金币账户充值完成后，人脉宝盒团队不予退款。
          <br>
          <span class="textBold5"> 6、 </span>用户确认，虚拟金币只能用于兑换“人脉宝盒”软件上的各类增值服务，任何情况下不得与人脉宝盒团队以外的第三方进行虚拟金币交易，亦不得在除“人脉宝盒”软件以外的第三方平台上进行交易；如违反前述约定，造成用户或第三方任何损失，人脉宝盒团队不负任何责任，且如人脉宝盒团队有理由怀疑用户的虚拟金币账户或使用情况有作弊或异常状况，人脉宝盒团队将拒绝该用户使用虚拟金币进行兑换，直至按本协议约定采取相关封禁措施。
          <br>
          <span class="textBold5"> 7、 </span>人脉宝盒团队有权基于安全等公平合法合理方面的考虑不时设定或修改涉及兑换的相关事项，包括但不限于兑换限额、兑换次数等。用户了解并确认人脉宝盒团队的前述设定或修改可能对用户的兑换产生一定的不便，用户对此没有异议。
          <br>
          <span class="textBold5">8、  </span>用户确认，除法律法规明确规定或本协议另有约定外，用户已兑换的任何收费服务不能以任何理由退购（即退换成虚拟金币或法定货币）或调换成其他服务。
          <br>
          <span class="textBold5"> 9、 </span>因用户自身的原因导致购买虚拟金币或兑换服务时发生任何错误、损失或责任，由用户自行负责，人脉宝盒团队不承担责任，包括但不限于：
          <br>
          <span class="textBold5">  (1)  </span>因用户的人脉宝盒账号丢失、被封禁或冻结；
          <br>
          <span class="textBold5">  (2)  </span>用户将密码告知他人导致的财产损失；
          <br>
          <span class="textBold5"> (3)  </span>因用户绑定的第三方支付机构账户的原因导致的任何损失或责任；
          <br>
          <span class="textBold5">  (4)  </span>其他用户故意或者重大过失或者违反法律法规导致的财产损失。
          <br>
          <span class="textBold5">10、  </span>用户在使用人脉宝盒团队提供的服务时，如出现违反国家法律法规、本协议约定或其他本软件对用户的管理规定的情形，人脉宝盒团队有权暂时或永久封禁用户的账号。账号封禁后至解禁（如有）前，用户账户上的剩余虚拟金币将依法依约被暂时冻结或全部扣除，不可继续用于兑换平台上的虚拟产品或服务，同时不予返还用户购买虚拟金币时的现金价值。
          <br>
          <span class="textBold5">11、  </span>
          用户确认并同意如用户主动注销账号，则用户已充值到账的虚拟金币以及用户权益等视为自动放弃，
          人脉宝盒团队不予返还相应的现金价值，也不会作出任何补偿。
          <br>
          <br>

          <span class="textBold">七、账户管理</span>
          <br>
          <span class="textBold5"> 1、</span>“人脉宝盒”账号的所有权归人脉宝盒团队所有，用户完成申请注册手续后，获得“人脉宝盒”帐号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。人脉宝盒团队因经营需要，有权回收用户的“人脉宝盒”帐号。
          <br>
          <span class="textBold5"> 2、</span>用户可以通过
          <span class="textBold5"> 1）</span>查看与编辑个人资料页；
          <span class="textBold5">2）</span>客服来查询、更改、删除、注销“人脉宝盒”帐户上的个人资料、注册信息及传送内容等，但需注意
          ，删除有关信息的同时也会删除用户储存在系统中的文字和图片。用户需承担该风险；
          <br>
          <span class="textBold5"> 3、</span>用户有责任妥善保管注册帐号信息及帐号密码的安全，因用户保管不善可能导致遭受盗号或密码失窃，责任由用户自行承担。用户需要对注册帐号以及密码下的行为承担法律责任。用户同意在任何情况下不使用其他用户的帐号或密码。在用户怀疑他人使用其帐号或密码时，用户同意立即通知人脉宝盒团队。
          <br>
          <span class="textBold5"> 4、</span>用户应遵守本协议的各项条款，正确、适当地使用本服务，如因用户违反本协议中的任何条款，人脉宝盒团队在通知用户后有权依据协议中断或终止对违约用户“人脉宝盒”帐号提供服务。同时，人脉宝盒团队保留在任何时候收回“人脉宝盒”帐号、用户名的权利。
          <br>
          <span class="textBold5">5、</span>如用户注册“人脉宝盒”帐号后一年不登录，通知用户后，人脉宝盒团队可以收回该帐号，以免造成资源浪费，由此造成的不利后果由用户自行承担。
          <br>
          <span class="textBold5">6、</span>用户可以通过客服来进行账号注销服务，用户确认注销账号是不可恢复的操作，用户应自行备份与人脉宝盒账号相关的信息和数据，
          用户确认操作之前与人脉宝盒账号相关的所有服务均已进行妥善处理。用户确认并同意注销账号后并不代表本人
          脉宝盒账号注销前的账号行为和相关责任得到豁免或减轻，如在注销期间，用户的账号被他人投诉、被国家机关调查或者正处于诉讼、
          仲裁程序中，人脉宝盒团队有权自行终止用户的账号注销并无需另行得到用户的同意。
          <br>
          <br>

          <span class="textBold">八、数据储存</span>
          <br>
          <span class="textBold5"> 1、 </span>人脉宝盒团队不对用户在本服务中相关数据的删除或储存失败负责。
          <br>
          <span class="textBold5"> 2、 </span>人脉宝盒团队可以根据实际情况自行决定用户在本服务中数据的最长储存期限（默认为3年），并在服务器上为其分配数据最大存储空间等。用户可根据自己的需要自行备份本服务中的相关数据。
          <br>
          <span class="textBold5"> 3、 </span>如用户停止使用本服务或本服务终止，人脉宝盒团队可以从服务器上永久地删除用户的数据。本服务停止、终止后，人脉宝盒团队没有义务向用户返还任何数据。
          <br>
          <br>
          <span class="textBold">九、风险承担</span>
          <br>
          <span class="textBold5"> 1、  </span>用户理解并同意，“人脉宝盒”仅为用户提供信息分享、传送及获取的平台，用户必须为自己注册帐号下的一切行为负责，包括用户所传送的任何内容以及由此产生的任何后果。用户应对“人脉宝盒”及本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。人脉宝盒团队无法且不会对因用户行为而导致的任何损失或损害承担责任。
          <br>
          如果用户发现任何人违反本协议约定或以其他不当的方式使用本服务，请立即向人脉宝盒团队举报或投诉，人脉宝盒团队将依本协议约定进行处理。
          <br>
          <span class="textBold5">  </span>  2、用户理解并同意，因业务发展需要，人脉宝盒团队保留单方面对本服务的全部或部分服务内容变更、暂停、终止或撤销的权利，用户需承担此风险。
          <br>
          <span class="textBold">十、知识产权声明</span>
          <br>
          <span class="textBold5"> 1、 </span>除本服务中涉及广告的知识产权由相应广告商享有外，人脉宝盒团队在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权均归人脉宝盒团队所有，但用户在使用本服务前对自己发布的内容已合法取得知识产权的除外。
          <br>
          <span class="textBold5"> 2、 </span>除另有特别声明外，人脉宝盒软件提供本服务时所依托软件的著作权、专利权及其他知识产权均归人脉宝盒团队所有。
          <br>
          <span class="textBold5"> 3、 </span>人脉宝盒软件在本服务中所涉及的图形、文字或其组成，其著作权或商标权归人脉宝盒团队所有。未经人脉宝盒团队事先书面同意，用户不得将人脉宝盒软件标识以任何方式展示或使用或作其他处理，也不得向他人表明用户有权展示、使用、或其他有权处理人脉宝盒软件标识的行为。
          <br>
          <span class="textBold5"> 4、</span>上述及其他任何人脉宝盒团队或相关广告商依法拥有的知识产权均受到法律保护，未经人脉宝盒团队或相关广告商书面许可，用户不得以任何形式进行使用或创造相关衍生作品。
          <br>
          <span class="textBold5"> 5、</span>用户在使用人脉宝盒服务时发表上传的文字、图片、视频、音频、软件以及表演等信息，此部分信息的知识产权归用户，责任由用户承担。但用户的发表、上传行为视为对人脉宝盒团队的授权，用户理解并同意授予人脉宝盒团队及其关联公司全球范围内完全免费、不可撤销、独家、永久、可转授权和可再许可的权利，包括但不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及《著作权法》规定的由著作权人享有的其他著作财产权利及邻接权利。人脉宝盒团队可自行选择是否使用以及使用方式，包括但不限于将前述信息在人脉宝盒团队旗下的服务平台上使用与传播，将上述信息再次编辑后使用，以及由人脉宝盒团队授权给合作方使用、编辑与传播等。
          <br>
          <br>
          <span class="textBold">十一、法律责任</span>
          <br>
          <span class="textBold5"> 1、 </span> 如果人脉宝盒团队发现或收到他人举报或投诉用户违反本协议约定的，人脉宝盒团队有权不经通知随时对相关内容，包括但不限于用户资料、记录进行审查、删除，并视情节轻重对违规帐号处以包括但不限于警告、帐号封禁 、设备封禁 、功能封禁的处罚，且通知用户处理结果。
          <br>
          <span class="textBold5"> 2、 </span> 因违反用户协议被封禁的用户，可以通过客服查询封禁期限，并在封禁期限届满后自助解封。其中，被实施功能封禁的用户会在封禁期届满后自动恢复被封禁功能。被封禁用户可向人脉宝盒团队网站相关页面提交申诉，人脉宝盒团队将对申诉进行审查，并自行合理判断决定是否变更处罚措施。
          <br>
          <span class="textBold5"> 3、 </span> 用户理解并同意，人脉宝盒团队有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。
          <br>
          <span class="textBold5"> 4、 </span> 用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿人脉宝盒团队与合作公司、关联公司，并使之免受损害。
          <br>
          <br>
          <span class="textBold">十二、不可抗力及其他免责事由</span>
          <br>
          <span class="textBold5">  1、 </span>用户理解并确认，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，人脉宝盒团队将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，人脉宝盒团队及合作单位在法律允许的范围内免责。
          <br>
          <span class="textBold5"> 2、 </span> 本服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影响本服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。
          <br>
          <span class="textBold5">3、  </span> 用户理解并确认，本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，人脉宝盒团队不承担任何责任。
          <br>
          <span class="textBold5"> 4、  </span>用户理解并确认，在使用本服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，人脉宝盒团队不承担任何责任。
          <br>
          <span class="textBold5">  5、 </span> 用户理解并确认，人脉宝盒团队需要定期或不定期地对“人脉宝盒”平台或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，人脉宝盒团队无需为此承担任何责任，但人脉宝盒团队应事先进行通告。
          <br>
          <span class="textBold5"> 6、  </span>人脉宝盒团队依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成人脉宝盒团队的义务或承诺，人脉宝盒团队不能保证及时发现违法违规或违约行为或进行相应处理。
          <br>
          <span class="textBold5">  7、 </span>用户理解并确认，对于人脉宝盒团队向用户提供的下列产品或者服务的质量缺陷及其引发的任何损失，人脉宝盒团队无需承担任何责任：
          <br>
          <span class="textBold5"> (1)  </span> 人脉宝盒团队向用户免费提供的服务；
          <br>
          <span class="textBold5"> (2)  </span> 人脉宝盒团队向用户赠送的任何产品或者服务。
          <br>
          <span class="textBold5"> 8、  </span>在任何情况下，人脉宝盒团队均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因用户使用“人脉宝盒”或本服务而遭受的利润损失，承担责任（即使人脉宝盒团队已被告知该等损失的可能性亦然）。尽管本协议中可能含有相悖的规定，人脉宝盒团队对用户承担的全部责任，无论因何原因或何种行为方式，始终不超过用户因使用人脉宝盒团队提供的服务而支付给人脉宝盒团队的费用(如有)。
          <br>
          <br>
          <span class="textBold">十三、服务的变更、中断、终止</span>
          <br>
          <span class="textBold5"> 1、 </span> 鉴于网络服务的特殊性，用户同意人脉宝盒团队有权随时变更、中断或终止部分或全部的服务（包括收费服务）。人脉宝盒团队变更、中断或终止的服务，人脉宝盒团队应当在变更、中断或终止之前通知用户，并应向受影响的用户提供等值的替代性的服务；如用户不愿意接受替代性的服务，如果该用户已经向人脉宝盒团队充值的虚拟金币，人脉宝盒团队应当按照该用户实际使用服务的情况扣除相应消耗虚拟金币和赠送虚拟金币之后，将剩余的虚拟金币退还用户的虚拟金币账户中。
          <br>
          <span class="textBold5"> 2、 </span> 如发生下列任何一种情形，人脉宝盒团队有权变更、中断或终止向用户提供的免费服务或收费服务，而无需对用户或任何第三方承担任何责任：
          <br>
          <span class="textBold5">(1)   </span> 根据法律规定用户应提交真实信息，而用户提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
          <br>
          <span class="textBold5"> (2)  </span> 用户违反相关法律法规或本协议的约定；
          <br>
          <span class="textBold5"> (3)  </span> 按照法律规定或有权机关的要求；
          <br>
          <span class="textBold5">  (4)  </span>出于安全的原因或其他必要的情形。
          <br>
          <br>
          <span class="textBold">十四、其他</span>
          <br>
          <span class="textBold5">  1、</span>人脉宝盒团队郑重提醒用户注意本协议中免除人脉宝盒团队责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。
          <br>
          <span class="textBold5"> 2、</span> 本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和人脉宝盒团队之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交人脉宝盒团队住所地有管辖权的人民法院管辖。
          <br>
          <span class="textBold5"> 3、</span>本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。
          <br>
          <span class="textBold5"> 4、 </span> 由于互联网高速发展，您与人脉宝盒团队签署的本协议列明的条款可能并不能完整罗列并覆盖您与人脉宝盒所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，人脉宝盒隐私权政策、人脉宝盒平台行为规范等均为本协议的补充协议，与本协议不可分割且具有同等法律效力。
          <br>

        </div>

        </div>
      </div>

      <template v-slot:footer>
        <a-button></a-button>
        <a-button> </a-button>
      </template>
    </a-modal>
  </div>

  <privacy-agreement
    v-model:visible="privacyAgreementPage"
  />

</template>

<script>
import { reactive, ref, toRefs, watch } from 'vue';
import privacyAgreement from '@/views/mRigister/components/privacyAgreement';


export default {
  components: {
    privacyAgreement,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const container = ref();
    const state = reactive({
      privacyAgreementPage: false,
    })
    watch(
      () => props.init,
      init => {
        console.log('init123', init);

      },
      { deep: true }
    );

    return {
      container,
      ...toRefs(state),
      close() {
        context.emit('update:visible', false);
      },
      openPrivacyAgreementPage() {
        state.privacyAgreementPage = true
      },

    }

  }


};
</script>

<style scoped lang='less'>
.modalClass /deep/ .ant-modal-body {
  height: 540px;
  overflow: auto;
  padding: 0px 24px;
  margin-top: 24px;
  font-size: 16px;

}
.modalClass /deep/ .ant-modal-footer {
  padding:0px;
}

</style>